<!--
 * @Descripttion: 404页面
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 08:59:04
-->
<template>
  <div class="not-found">
    <img src="../assets/404.jpeg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .not-found {
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow: hidden;
  }
  .not-found img {
    width: auto;
    height: 100%;
  }
</style>
